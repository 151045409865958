import React from "react";
import { SnapshotResponse } from "../tekster/Types/Snapshot";
import RapportChapter from "./RapportChapter";
import { Title } from "../components/Headings/Headings";
import { ForelopigRapportOverordnetIngress } from "../tekster/OverordnetIngress/ForelopigRapport/ForelopigRapportOverordnetIngress";
import { EndeligRapportOverordnetIngress } from "../tekster/OverordnetIngress/EndeligRapport/EndeligRapportOverordnetIngress";
import { getText } from "../tekster/Overskrifts/Overskrifts";

type RapportTittelProps = {
  snapshot: SnapshotResponse;
  isForelopigRapport: boolean;
};

const RapportTittel: React.FC<RapportTittelProps> = ({
  snapshot,
  isForelopigRapport,
}) => {
  const tittel =
    isForelopigRapport === true
      ? getText(snapshot.omTilsynet?.malform, (t) => t.tittelForelopigRapport)
      : getText(snapshot.omTilsynet?.malform, (t) => t.tittelEndeligRapport);
  return (
    <RapportChapter>
      <Title>{tittel}</Title>
      {isForelopigRapport === true ? (
        <ForelopigRapportOverordnetIngress snapshot={snapshot} />
      ) : (
        <EndeligRapportOverordnetIngress snapshot={snapshot} visBrudd />
      )}
    </RapportChapter>
  );
};

export default RapportTittel;
