import {
  PubliseringInfoResponse,
  PubliseringStatusoppdatering,
  PublisertTilsynResponse,
} from "../../ApiClients";

export const getStatusoppdateringLastPublisering = (
  publiseringer: PubliseringInfoResponse[],
) => {
  return publiseringer.slice(-1)[0].statusoppdatering;
};

export const isVedtakEndret = (tilsyn: PublisertTilsynResponse) => {
  const statusoppdateringLastPublisering = getStatusoppdateringLastPublisering(
    tilsyn.publiseringer,
  );
  return (
    statusoppdateringLastPublisering ===
      PubliseringStatusoppdatering.VedtakOmgjortKd ||
    statusoppdateringLastPublisering ===
      PubliseringStatusoppdatering.VedtakOpphevetKd ||
    statusoppdateringLastPublisering ===
      PubliseringStatusoppdatering.Avslutningsbrev ||
    statusoppdateringLastPublisering ===
      PubliseringStatusoppdatering.VedtakStadfestetKd
  );
};
