import { Footer as UrcFooter } from "@utdanningsdirektoratet/layout";
import React from "react";
import { NavLink } from "react-router-dom";
import { onKeyDown } from "@utdanningsdirektoratet/utils/uu";
import {
  ApplicationInsightsCookieVendor,
  CookieBanner,
  MatomoCookieVendor,
  NecessaryCookie,
  SentryCookieVendor,
  StatisticsCookie,
  UidpCookieVendor,
} from "@utdanningsdirektoratet/cookiebanner";
import routes from "../routes";

const Footer: React.FC = () => {
  const [cookieState, setCookieState] = React.useState(false);
  const items = [
    <a
      href="https://www.udir.no/regelverk-og-tilsyn/tilsyn/"
      target="_blank"
      rel="noopener noreferrer"
    >
      Om tilsyn
    </a>,
    <a
      href="https://www.udir.no/om-udir/kontakt-oss/"
      target="_blank"
      rel="noopener noreferrer"
    >
      Kontaktinformasjon
    </a>,
    <a
      href="https://uustatus.no/nb/erklaringer/publisert/99ccc7c7-2fcd-48cc-bbff-9d71cba3e6a0"
      target="_blank"
      rel="noopener noreferrer"
    >
      Tilgjengelighetserklæring
    </a>,
    <NavLink to={routes.personvern}>Personvernserklæring</NavLink>,
    <div
      role="link"
      onClick={() => setCookieState(true)}
      onKeyDown={onKeyDown(() => setCookieState(true))}
      tabIndex={0}
    >
      Informasjonskapsler
    </div>,
  ];
  return (
    <>
      <UrcFooter
        items={items}
        udirLogo
        linkButton={{ href: "https://udir.no", title: "Udir.no" }}
      />
      <CookieBanner
        show={cookieState}
        necessaryCookie={NecessaryCookie([
          UidpCookieVendor,
          SentryCookieVendor,
        ])}
        cookies={[
          StatisticsCookie([
            ApplicationInsightsCookieVendor,
            MatomoCookieVendor,
          ]),
        ]}
        onAccept={() => setCookieState(false)}
      />
    </>
  );
};

export default Footer;
