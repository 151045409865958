type MalformText = {
  nb: string;
  nn: string;
};
export type TextType = {
  // Overskrifts
  tittelForelopigRapport: MalformText;
  tittelEndeligRapport: MalformText;
  innledning: MalformText;
  brudd: MalformText;
  muligBrudd: MalformText;
  ingenBrudd: MalformText;
  reaksjoner: MalformText;
  reaksjonerForelopigRapport: MalformText;
  oppfolging: MalformText;
  regelverkOgVurdering: MalformText;
  rettTilAKlage: MalformText;
  klageAdgang: MalformText;
  uttalelseForelopigRapport: MalformText;
  erklaeringOgRedegjorelse: MalformText;
  tilbakemelding: MalformText;
  innsendingAvDokumentasjon: MalformText;
  kontrollsporsmalHeaderSvar: MalformText;
  kontrollsporsmalHeaderSvarForelopigRapport: MalformText;
  forelopigKonklusjon: MalformText;

  // Reaksjoner
  reaksjonHoldeTilbakeTilskuddVedtak: MalformText;
  reaksjonHoldeTilbakeTilskuddVarsel: MalformText;

  reaksjonTilbakebetalingAvTilskuddVedtak: MalformText;
  reaksjonTilbakebetalingAvTilskuddVarsel: MalformText;

  reaksjonStengePermanentVedtak: MalformText;
  reaksjonStengePermanentVarsel: MalformText;

  reaksjonRetteForholdeneVedtak: MalformText;
  reaksjonRetteForholdeneVarsel: MalformText;

  reaksjonTidsbegrensetStengingVedtak: MalformText;
  reaksjonTidsbegrensetStengingVarsel: MalformText;

  reaksjonTrekkeTilbakeGodkjenningVedtak: MalformText;
  reaksjonTrekkeTilbakeGodkjenningVarsel: MalformText;

  brukerIngenReaksjoner: MalformText;
  // OptionTexts
  gaTilInnsendingAv: MalformText;
  gaTilTidligereInnsending: MalformText;

  toggleKontrollsporsmalWithBrudd: MalformText;
  toggleKontrollsporsmalWithBruddForelopigRapport: MalformText;
  toggleKontrollsporsmalWithoutBrudd: MalformText;

  gaTilDokumentasjonInnsending: MalformText;
  gaTilTidligereInnsendtDokumentasjon: MalformText;
  loggInnOgGaTilDokumentasjonInnsending: MalformText;
  loggInnOgGaTilTidligereInnsendtDokumentasjon: MalformText;

  lastNedForelopigRapport: MalformText;
  lastNedEndeligRapport: MalformText;

  // TilsynsOversikt
  oversiktTilbakemelding: MalformText;
  oversiktRetting: MalformText;
  oversiktKlage: MalformText;
  oversiktKlageUtenFrist: MalformText;

  tilsynApnet: MalformText;
  tilsynAvsluttet: MalformText;

  statusoppdateringKlageMottatt: MalformText;
  statusoppdateringKlageOversendtKd: MalformText;
  statusoppdateringVedtakOpphevetKd: MalformText;
  statusoppdateringVedtakOmgjortUdir: MalformText;
  statusoppdateringVedtakStadfestetKd: MalformText;
  statusoppdateringVedtakOmgjortKd: MalformText;
  statusoppdateringAvslutningsbrev: MalformText;
  statusoppdateringVedtakDelvisStadfestetKd: MalformText;
};

const common = {
  gaTilInnsendingAv: "Gå til innsending av",
  gaTilTidligereInnsendingNb: "Se tidligere",
  gaTilTidligereInnsendingNn: "Sjå tidlegare",
};

export const Texts: TextType = {
  // Overskrifts
  tittelForelopigRapport: {
    nb: "Foreløpig tilsynsrapport",
    nn: "Førebels tilsynsrapport",
  },
  tittelEndeligRapport: {
    nb: "Tilsynsrapport",
    nn: "Tilsynsrapport",
  },
  innledning: {
    nb: "Innledning",
    nn: "Innleiing",
  },
  brudd: {
    nb: "Brudd på regelverket",
    nn: "Brot på regelverket",
  },
  muligBrudd: {
    nb: "Mulig brudd på regelverket",
    nn: "Mogleg brot på regelverket",
  },
  ingenBrudd: {
    nb: "Ikke brudd på regelverket",
    nn: "Ikkje brot på regelverket",
  },
  reaksjoner: {
    nb: "Våre reaksjoner",
    nn: "Våre reaksjonar",
  },
  reaksjonerForelopigRapport: {
    nb: "Våre varslede reaksjoner",
    nn: "Våre varsla reaksjonar",
  },
  oppfolging: {
    nb: "Hva skjer nå?",
    nn: "Kva skjer no?",
  },
  regelverkOgVurdering: {
    nb: "Regelverk og vurdering",
    nn: "Regelverk og vurdering",
  },
  klageAdgang: {
    nb: "Dere har rett til å klage",
    nn: "De har rett til å klage",
  },
  rettTilAKlage: {
    nb: "Dere kan klage på pålegget om å sende inn dokumentasjon",
    nn: "De kan klage på pålegget om å sende inn dokumentasjon",
  },
  uttalelseForelopigRapport: {
    nb: "Uttalelse på foreløpig rapport",
    nn: "Uttale på førebels rapport",
  },
  erklaeringOgRedegjorelse: {
    nb: "Erklæring og redegjørelse om hvordan pålegg er rettet",
    nn: "Erklæring og utgreiing om korleis pålegg er retta",
  },
  tilbakemelding: {
    nb: "Dere kan gi tilbakemelding på rapporten",
    nn: "De kan gi tilbakemelding på rapporten",
  },
  innsendingAvDokumentasjon: {
    nb: "Innsending av dokumentasjon",
    nn: "Innsending av dokumentasjon",
  },

  // Reaksjoner
  reaksjonHoldeTilbakeTilskuddVedtak: {
    nb: "Vedtak om at vi vil holde tilbake tilskudd",
    nn: "Vedtak om at vi vil halde tilbake tilskot",
  },
  reaksjonHoldeTilbakeTilskuddVarsel: {
    nb: "Varsel om vedtak om å holde tilbake tilskudd",
    nn: "Varsel om vedtak om å halde tilbake tilskot",
  },

  reaksjonTilbakebetalingAvTilskuddVedtak: {
    nb: "Vedtak om at dere må betale tilbake tilskudd",
    nn: "Vedtak om at de må betale tilbake tilskot",
  },
  reaksjonTilbakebetalingAvTilskuddVarsel: {
    nb: "Varsel om at dere må betale tilbake tilskudd",
    nn: "Varsel om at de må betale tilbake tilskot",
  },

  reaksjonStengePermanentVedtak: {
    nb: "Vedtak om at vi stenger barnehagen permanent",
    nn: "Vedtak om at vi stenger barnehagen permanent",
  },
  reaksjonStengePermanentVarsel: {
    nb: "Varsel om at vi stenger barnehagen permanent",
    nn: "Varsel om at vi stenger barnehagen permanent",
  },

  reaksjonRetteForholdeneVedtak: {
    nb: "Pålegg om å rette forholdene",
    nn: "Pålegg om å rette forholda",
  },
  reaksjonRetteForholdeneVarsel: {
    nb: "Varsel om at dere må rette forholdene",
    nn: "Varsel om at de må rette forholda",
  },

  reaksjonTidsbegrensetStengingVedtak: {
    nb: "Vedtak om at vi stenger barnehagen i en tidsbegrenset periode",
    nn: "Vedtak om at vi stenger barnehagen i ein tidsavgrensa periode",
  },
  reaksjonTidsbegrensetStengingVarsel: {
    nb: "Varsel om at vi stenger barnehagen i en tidsbegrenset periode",
    nn: "Varsel om at vi stenger barnehagen i ein tidsavgrensa periode",
  },

  reaksjonTrekkeTilbakeGodkjenningVedtak: {
    nb: "Vedtak om at vi vil trekke tilbake godkjenningen",
    nn: "Vedtak om at vi vil trekke tilbake godkjenninga",
  },
  reaksjonTrekkeTilbakeGodkjenningVarsel: {
    nb: "Varsel om at vi vil trekke tilbake godkjenningen",
    nn: "Varsel om at vi vil trekke tilbake godkjenninga",
  },

  brukerIngenReaksjoner: {
    nb: "Vi bruker ingen reaksjoner ettersom vi ikke har avdekket brudd på regelverket.",
    nn: "Vi bruker ingen reaksjonar ettersom vi ikkje har avdekt brot på regelverket.",
  },
  kontrollsporsmalHeaderSvar: {
    nb: "Svar",
    nn: "Svar",
  },
  kontrollsporsmalHeaderSvarForelopigRapport: {
    nb: "Foreløpig svar",
    nn: "Førebels svar",
  },

  // OptionTexts
  gaTilInnsendingAv: {
    nb: common.gaTilInnsendingAv,
    nn: common.gaTilInnsendingAv,
  },
  gaTilTidligereInnsending: {
    nb: common.gaTilTidligereInnsendingNb,
    nn: common.gaTilTidligereInnsendingNn,
  },

  toggleKontrollsporsmalWithBrudd: {
    nb: "Vis kontrollspørsmål med brudd",
    nn: "Vis kontrollspørsmål med brot",
  },
  toggleKontrollsporsmalWithBruddForelopigRapport: {
    nb: "Vis kontrollspørsmål med mulig brudd",
    nn: "Vis kontrollspørsmål med mogleg brot",
  },
  toggleKontrollsporsmalWithoutBrudd: {
    nb: "Vis alle kontrollspørsmål",
    nn: "Vis alle kontrollspørsmål",
  },

  gaTilDokumentasjonInnsending: {
    nb: `${common.gaTilInnsendingAv} dokumentasjon`,
    nn: `${common.gaTilInnsendingAv} dokumentasjon`,
  },
  gaTilTidligereInnsendtDokumentasjon: {
    nb: `Se innsendt dokumentasjon`,
    nn: `Sjå innsendt dokumentasjon`,
  },
  loggInnOgGaTilDokumentasjonInnsending: {
    nb: "Logg inn for å sende inn dokumentasjon",
    nn: "Logg inn for å sende inn dokumentasjon",
  },
  loggInnOgGaTilTidligereInnsendtDokumentasjon: {
    nb: `Logg inn for å ${common.gaTilTidligereInnsendingNb.toLocaleLowerCase()} innsendt dokumentasjon`,
    nn: `Logg inn for å ${common.gaTilTidligereInnsendingNn.toLocaleLowerCase()} innsendt dokumentasjon`,
  },

  lastNedForelopigRapport: {
    nb: "Last ned foreløpig tilsynsrapport",
    nn: "Last ned førebels tilsynsrapport",
  },
  lastNedEndeligRapport: {
    nb: "Last ned tilsynsrapport",
    nn: "Last ned tilsynsrapport",
  },

  // TilsynsOversikt
  oversiktTilbakemelding: {
    nb: "Virksomheten kan uttale seg om den foreløpige tilsynsrapporten innen",
    nn: "Virksomheten kan uttale seg om den foreløpige tilsynsrapporten innen",
  },
  oversiktRetting: {
    nb: "Virksomheten må rette forholdene og sende inn redegjørelse innen",
    nn: "Virksomheten må rette forholdene og sende inn redegjørelse innen",
  },
  oversiktKlage: {
    nb: "Virksomheten kan klage på tilsynsrapporten innen",
    nn: "Virksomheten kan klage på tilsynsrapporten innen",
  },
  oversiktKlageUtenFrist: {
    nb: "Virksomheten kan klage på tilsynsrapporten",
    nn: "Virksomheten kan klage på tilsynsrapporten",
  },
  forelopigKonklusjon: {
    nb: "Vår foreløpige konklusjon",
    nn: "Vår førebels konklusjon",
  },

  tilsynApnet: {
    nb: "Tilsyn åpnet",
    nn: "Tilsyn opna",
  },
  tilsynAvsluttet: {
    nb: "Tilsyn avsluttet",
    nn: "Tilsyn avslutta",
  },

  statusoppdateringKlageMottatt: {
    nb: "Klage mottatt",
    nn: "Klage mottatt",
  },
  statusoppdateringKlageOversendtKd: {
    nb: "Klage oversendt til Kunnskapsdepartementet",
    nn: "Klage oversendt til Kunnskapsdepartementet",
  },
  statusoppdateringVedtakOpphevetKd: {
    nb: "Vedtak opphevet av Kunnskapsdepartementet",
    nn: "Vedtak oppheva av Kunnskapsdepartementet",
  },
  statusoppdateringVedtakOmgjortUdir: {
    nb: "Vedtak omgjort av Utdanningsdirektoratet",
    nn: "Vedtak omgjort av Utdanningsdirektoratet",
  },
  statusoppdateringVedtakStadfestetKd: {
    nb: "Vedtak stadfestet av Kunnskapsdepartementet",
    nn: "Vedtak stadfesta av Kunnskapsdepartementet",
  },
  statusoppdateringVedtakOmgjortKd: {
    nb: "Vedtak omgjort av Kunnskapsdepartementet",
    nn: "Vedtak omgjort av Kunnskapsdepartementet",
  },
  statusoppdateringAvslutningsbrev: {
    nb: "Avslutningsbrev",
    nn: "Avslutningsbrev",
  },
  statusoppdateringVedtakDelvisStadfestetKd: {
    nb: "Vedtak delvis stadfestet av Kunnskapsdepartementet",
    nn: "Vedtak delvis stadfesta av Kunnskapsdepartementet",
  },
};

interface TextSelector {
  (teksts: TextType): MalformText;
}

export const getText = (
  malform: string | undefined,
  textSelector: TextSelector,
): string => {
  const text = textSelector(Texts);
  return malform === "Nynorsk" ? text.nn : text.nb;
};
