import React from "react";
import { LovEnumIdentifier, SnapshotResponse } from "../Types/Snapshot";
import { Paragraph } from "../../components/Headings/Headings";

type IProps = {
  snapshot: SnapshotResponse;
};

export const StatusoppdateringAvslutningsbrev: React.FC<IProps> = ({
  snapshot,
}) => {
  if (snapshot.omTilsynet?.malform === "Nynorsk") {
    return (
      <>
        <Paragraph>
          {snapshot.omTilsynet.lovEnumIdentifier ===
          LovEnumIdentifier.Barnehageloven
            ? "Barnehagen"
            : "Skulen"}{" "}
          har klaga på vedtaket vårt. Kunnskapsdepartementet har gitt{" "}
          {snapshot.omTilsynet.lovEnumIdentifier ===
          LovEnumIdentifier.Barnehageloven
            ? "barnehagen"
            : "skulen"}{" "}
          medhald i klaga, og oppheva vedtaket. Pålegget vårt i tilsynsrapporten
          er ikkje gyldig.
        </Paragraph>
        <Paragraph>Tilsynet er avslutta.</Paragraph>
      </>
    );
  }

  return (
    <>
      <Paragraph>
        {snapshot.omTilsynet?.lovEnumIdentifier ===
        LovEnumIdentifier.Barnehageloven
          ? "Barnehagen"
          : "Skolen"}{" "}
        har klaget på vedtaket vårt. Kunnskapsdepartementet har gitt{" "}
        {snapshot.omTilsynet?.lovEnumIdentifier ===
        LovEnumIdentifier.Barnehageloven
          ? "barnehagen"
          : "skolen"}{" "}
        medhold i klagen, og opphevet vedtaket. Pålegget vårt i tilsynsrapporten
        er ikke gyldig.
      </Paragraph>
      <Paragraph>Tilsynet er avsluttet.</Paragraph>
    </>
  );
};
