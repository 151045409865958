import * as React from "react";

import {
  PubliseringStatusoppdatering,
  PublisertTilsynResponse,
} from "ApiClients";
import { Box } from "components/Box";
import {
  Heading2,
  Paragraph,
} from "SharedComponents/components/Headings/Headings";
import { StatusoppdateringKlageMottatt } from "SharedComponents/tekster/Statusoppdatering/StatusoppdateringKlageMottatt";
import { StatusoppdateringVedtakOmgjortKD } from "SharedComponents/tekster/Statusoppdatering/StatusoppdateringVedtakOmgjortKD";
import { StatusoppdateringVedtakOpphevetKD } from "SharedComponents/tekster/Statusoppdatering/StatusoppdateringVedtakOpphevetKD";
import { StatusoppdateringKlageOversendtKD } from "SharedComponents/tekster/Statusoppdatering/StatusoppdateringKlageOversendtKD";
import { StatusoppdateringVedtakOmgjortUdir } from "SharedComponents/tekster/Statusoppdatering/StatusoppdateringVedtakOmgjortUdir";
import { StatusoppdateringVedtakStadfestetKD } from "SharedComponents/tekster/Statusoppdatering/StatusoppdateringVedtakStadfestetKD";
import { StatusoppdateringAvslutningsbrev } from "SharedComponents/tekster/Statusoppdatering/StatusoppdateringAvslutningsbrev";
import { StatusoppdateringVedtakDelvisStadfestetKD } from "SharedComponents/tekster/Statusoppdatering/StatusoppdateringVedtakDelvisStadfestetKD";
import { getText } from "SharedComponents/tekster/Overskrifts/Overskrifts";
import { getStatusoppdateringLastPublisering } from "../utils";

type TilsynStatusProps = {
  tilsyn: PublisertTilsynResponse;
};

export const TilsynStatusoppdatering: React.FC<TilsynStatusProps> = ({
  tilsyn,
}) => {
  const statusoppdateringLastPublisering = getStatusoppdateringLastPublisering(
    tilsyn.publiseringer,
  );
  const getStatusHeadline = () => {
    switch (statusoppdateringLastPublisering) {
      case PubliseringStatusoppdatering.KlageMottatt:
        return getText(tilsyn.malform, (t) => t.statusoppdateringKlageMottatt);
      case PubliseringStatusoppdatering.KlageOversendtKd:
        return getText(
          tilsyn.malform,
          (t) => t.statusoppdateringKlageOversendtKd,
        );
      case PubliseringStatusoppdatering.VedtakOpphevetKd:
        return getText(
          tilsyn.malform,
          (t) => t.statusoppdateringVedtakOpphevetKd,
        );
      case PubliseringStatusoppdatering.VedtakOmgjortUdir:
        return getText(
          tilsyn.malform,
          (t) => t.statusoppdateringVedtakOmgjortUdir,
        );
      case PubliseringStatusoppdatering.VedtakStadfestetKd:
        return getText(
          tilsyn.malform,
          (t) => t.statusoppdateringVedtakStadfestetKd,
        );
      case PubliseringStatusoppdatering.VedtakOmgjortKd:
        return getText(
          tilsyn.malform,
          (t) => t.statusoppdateringVedtakOmgjortUdir,
        );
      case PubliseringStatusoppdatering.Avslutningsbrev:
        return getText(
          tilsyn.malform,
          (t) => t.statusoppdateringAvslutningsbrev,
        );
      case PubliseringStatusoppdatering.VedtakDelvisStadfestetKd:
        return getText(
          tilsyn.malform,
          (t) => t.statusoppdateringVedtakDelvisStadfestetKd,
        );
      default:
        return null;
    }
  };
  const getStatusText = () => {
    switch (statusoppdateringLastPublisering) {
      case PubliseringStatusoppdatering.KlageMottatt:
        return <StatusoppdateringKlageMottatt snapshot={tilsyn.snapshot} />;
      case PubliseringStatusoppdatering.KlageOversendtKd:
        return <StatusoppdateringKlageOversendtKD snapshot={tilsyn.snapshot} />;
      case PubliseringStatusoppdatering.VedtakOpphevetKd:
        return <StatusoppdateringVedtakOpphevetKD snapshot={tilsyn.snapshot} />;
      case PubliseringStatusoppdatering.VedtakOmgjortUdir:
        return (
          <StatusoppdateringVedtakOmgjortUdir snapshot={tilsyn.snapshot} />
        );
      case PubliseringStatusoppdatering.VedtakStadfestetKd:
        return (
          <StatusoppdateringVedtakStadfestetKD snapshot={tilsyn.snapshot} />
        );
      case PubliseringStatusoppdatering.VedtakOmgjortKd:
        return <StatusoppdateringVedtakOmgjortKD snapshot={tilsyn.snapshot} />;
      case PubliseringStatusoppdatering.Avslutningsbrev:
        return <StatusoppdateringAvslutningsbrev snapshot={tilsyn.snapshot} />;
      case PubliseringStatusoppdatering.VedtakDelvisStadfestetKd:
        return (
          <StatusoppdateringVedtakDelvisStadfestetKD
            snapshot={tilsyn.snapshot}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box $background="white" className="u--marginBottom2">
      <Heading2>{getStatusHeadline()}</Heading2>
      <Paragraph>{getStatusText()}</Paragraph>
    </Box>
  );
};
