import React from "react";
import { LovEnumIdentifier, SnapshotResponse } from "../Types/Snapshot";
import { Paragraph } from "../../components/Headings/Headings";

type IProps = {
  snapshot: SnapshotResponse;
};

export const StatusoppdateringKlageOversendtKD: React.FC<IProps> = ({
  snapshot,
}) => {
  if (snapshot.omTilsynet?.malform === "Nynorsk") {
    return (
      <Paragraph>
        {snapshot.omTilsynet.lovEnumIdentifier ===
        LovEnumIdentifier.Barnehageloven
          ? "Barnehagen"
          : "Skulen"}{" "}
        har klaga på vedtaket vårt. Vi har sendt klagen til
        Kunnskapsdepartementet, som vil gjera ei endeleg vurdering av saka.
      </Paragraph>
    );
  }

  return (
    <Paragraph>
      {snapshot.omTilsynet?.lovEnumIdentifier ===
      LovEnumIdentifier.Barnehageloven
        ? "Barnehagen"
        : "Skolen"}{" "}
      har klaget på vedtaket vårt. Vi har sendt klagen til
      Kunnskapsdepartementet, som vil gjøre en endelig vurdering av saken.
    </Paragraph>
  );
};
