import React from "react";
import { LovEnumIdentifier, SnapshotResponse } from "../Types/Snapshot";
import { Paragraph } from "../../components/Headings/Headings";

type IProps = {
  snapshot: SnapshotResponse;
};

export const StatusoppdateringVedtakDelvisStadfestetKD: React.FC<IProps> = ({
  snapshot,
}) => {
  if (snapshot.omTilsynet?.malform === "Nynorsk") {
    return (
      <Paragraph>
        {snapshot.omTilsynet.lovEnumIdentifier ===
        LovEnumIdentifier.Barnehageloven
          ? "Barnehagen"
          : "Skulen"}{" "}
        har klaga på vedtaket vårt. Kunnskapsdepartementet har stadfesta delar
        av vedtaket, og har oppheva eller omgjort delar av vedtaket.
      </Paragraph>
    );
  }

  return (
    <Paragraph>
      {snapshot.omTilsynet?.lovEnumIdentifier ===
      LovEnumIdentifier.Barnehageloven
        ? "Barnehagen"
        : "Skolen"}{" "}
      har klaget på vedtaket vårt. Kunnskapsdepartementet har stadfestet deler
      av vedtaket, og har opphevet eller omgjort deler av vedtaket.
    </Paragraph>
  );
};
