import React from "react";
import { LovEnumIdentifier, SnapshotResponse } from "../Types/Snapshot";
import { Paragraph } from "../../components/Headings/Headings";

type IProps = {
  snapshot: SnapshotResponse;
};

export const StatusoppdateringVedtakOmgjortKD: React.FC<IProps> = ({
  snapshot,
}) => {
  if (snapshot.omTilsynet?.malform === "Nynorsk") {
    return (
      <Paragraph>
        {snapshot.omTilsynet.lovEnumIdentifier ===
        LovEnumIdentifier.Barnehageloven
          ? "Barnehagen"
          : "Skulen"}{" "}
        har klaga på vedtaket vårt. Kunnskapsdepartementet har vurdert saka og
        fatta eit nytt vedtak.{" "}
        {snapshot.omTilsynet.lovEnumIdentifier ===
        LovEnumIdentifier.Barnehageloven
          ? "Barnehagen"
          : "Skulen"}{" "}
        må følgja opp pålegget i det nye vedtaket.
      </Paragraph>
    );
  }

  return (
    <Paragraph>
      {snapshot.omTilsynet?.lovEnumIdentifier ===
      LovEnumIdentifier.Barnehageloven
        ? "Barnehagen"
        : "Skolen"}{" "}
      har klaget på vedtaket vårt. Kunnskapsdepartementet har vurdert saken og
      fattet et nytt vedtak.{" "}
      {snapshot.omTilsynet?.lovEnumIdentifier ===
      LovEnumIdentifier.Barnehageloven
        ? "Barnehagen"
        : "Skolen"}{" "}
      må følge opp pålegget i det nye vedtaket.
    </Paragraph>
  );
};
