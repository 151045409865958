import React from "react";
import { LovEnumIdentifier, SnapshotResponse } from "../Types/Snapshot";
import { Paragraph } from "../../components/Headings/Headings";

type IProps = {
  snapshot: SnapshotResponse;
};

export const StatusoppdateringVedtakStadfestetKD: React.FC<IProps> = ({
  snapshot,
}) => {
  if (snapshot.omTilsynet?.malform === "Nynorsk") {
    return (
      <Paragraph>
        {snapshot.omTilsynet.lovEnumIdentifier ===
        LovEnumIdentifier.Barnehageloven
          ? "Barnehagen"
          : "Skulen"}{" "}
        har klaga på vedtaket vårt. Kunnskapsdepartementet har ikkje gitt{" "}
        {snapshot.omTilsynet.lovEnumIdentifier ===
        LovEnumIdentifier.Barnehageloven
          ? "barnehagen"
          : "skulen"}{" "}
        medhald i klagen, men stadfesta vedtaket vårt.{" "}
        {snapshot.omTilsynet.lovEnumIdentifier ===
        LovEnumIdentifier.Barnehageloven
          ? "Barnehagen"
          : "Skulen"}{" "}
        må følgja opp pålegget vårt i tilsynsrapporten.
      </Paragraph>
    );
  }

  return (
    <Paragraph>
      {snapshot.omTilsynet?.lovEnumIdentifier ===
      LovEnumIdentifier.Barnehageloven
        ? "Barnehagen"
        : "Skolen"}{" "}
      Skolen har klaget på vedtaket vårt. Kunnskapsdepartementet har ikke gitt{" "}
      {snapshot.omTilsynet?.lovEnumIdentifier ===
      LovEnumIdentifier.Barnehageloven
        ? "barnehagen"
        : "skolen"}{" "}
      medhold i klagen, men stadfestet vedtaket vårt.{" "}
      {snapshot.omTilsynet?.lovEnumIdentifier ===
      LovEnumIdentifier.Barnehageloven
        ? "Barnehagen"
        : "Skolen"}{" "}
      må følge opp pålegget vårt i tilsynsrapporten.
    </Paragraph>
  );
};
